'use client';
// ^ Necessary if using App Router to ensure this file runs on the client

import { datadogLogs, LogsEvent, LogsEventDomainContext } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const endpoint = process.env.CL_ENDPOINT;

const organizationFromEndPoint = endpoint.split('://')[1].split('.')[0];
const DRAFT_ENV = process.env.DRAFT_ENV || 'prod';
const DD_APPLICATION_ID = process.env.DD_APPLICATION_ID || false;
const DD_CLIENT_TOKEN = process.env.DD_CLIENT_TOKEN || false;

if (DD_CLIENT_TOKEN) {
  // regex patterns to identify known bot instances:
  const botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';

  const regex = new RegExp(botPattern, 'i');

  const isBot = typeof window !== 'undefined' ? regex.test(navigator.userAgent) : false;

  datadogLogs.init({
    clientToken: DD_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'wineplatform-' + organizationFromEndPoint,
    env: DRAFT_ENV,
    forwardErrorsToLogs: true,
    sessionSampleRate: isBot ? 0 : 100,
    version: '0.0.1',
    beforeSend: (event: LogsEvent, context: LogsEventDomainContext) => {
      // skip log script error -> https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#script-error
      if (['Uncaught "Script error."', 'Error: Script error.'].includes(event.message)) {
        return false;
      }
      // skip analytics network error
      if (event.origin == 'network' && event.http?.url.includes('analytics')) {
        return false;
      }
      return true;
    },
  });
  if (DD_APPLICATION_ID) {
    datadogRum.init({
      applicationId: DD_APPLICATION_ID,
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'wineplatform-' + organizationFromEndPoint,
      env: DRAFT_ENV,
      version: '0.0.1',
      sessionSampleRate: isBot ? 0 : 10,
      sessionReplaySampleRate: isBot ? 0 : 1,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      // Specify URLs to propagate trace headers for connection between RUM and backend trace
      allowedTracingUrls: [
        { match: 'https://example.com/api/', propagatorTypes: ['tracecontext'] },
        { match: process.env.CL_ENDPOINT, propagatorTypes: ['tracecontext'] },
        { match: process.env.HOST, propagatorTypes: ['tracecontext'] },
      ],
    });
  }
}

export default function DatadogInit(): null {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
